@font-face {
  font-family: Audi;
  font-style: normal;
  font-weight: 700;
  src: url("./font-audi/AudiType-ExtendedBold.woff")
    format("woff");
}

@font-face {
  font-family: Audi;
  font-style: normal;
  font-weight: 500;
  src: url("./font-audi/AudiType-ExtendedNormal.woff")
    format("woff");
}

@font-face {
  font-family: AudiWide;
  font-style: normal;
  font-weight: 700;
  src: url("./font-audi/AudiType-WideBold.woff2") format("woff2");
}

@font-face {
  font-family: AudiWide;
  font-style: normal;
  font-weight: 500;
  src: url("./font-audi/AudiType-WideNormal.woff2")
      format("woff2"),
    url("./font-audi/AudiType-WideNormal.woff") format("woff");
}

@font-face {
  font-family: AudiWide;
  font-style: normal;
  font-weight: 300;
  src: url("./font-audi/AudiType-WideLight.woff2")
      format("woff2"),
    url("./font-audi/AudiType-WideLight.woff") format("woff");
}

@font-face {
  font-family: AudiLatin;
  src: url("./font-audi/AudiTypeScreen-Latin-Bold.woff2")
      format("woff2"),
    url("./font-audi/AudiTypeScreen-Latin-Bold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AudiLatin;
  src: url("./font-audi/AudiTypeScreen-Latin-Normal.woff2")
      format("woff2"),
    url("./font-audi/AudiTypeScreen-Latin-Normal.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AudiLatin;
  src: url("./font-audi/AudiTypeScreen-Latin-Light.woff2")
      format("woff2"),
    url("./font-audi/AudiTypeScreen-Latin-Light.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
