@tailwind base;
@tailwind components;
@tailwind utilities;

/* Disable zoom and swipe gestures on kiosk*/
html {
    touch-action:none;
    overscroll-behavior-x: none
}

body {
    overscroll-behavior-x: none;
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE */
    user-select: none; /* Standard syntax */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Audi theme */
.audi-theme {
    font-family: AudiLatin, sans-serif;
    @apply text-gray-darker;
    /* add more styles specific to Audi theme here */
}

/* MyWay theme */
.myway-theme {
    font-family: Poppins, sans-serif;
    @apply text-gray-darker;
    /* Add more styles specific to MyWay theme here */
}

/* Buttons */
.btn{
    @apply py-2.5 px-6 bg-gray-lighter cursor-pointer;
}

.myway-theme {
    .btn-primary{
        @apply bg-green text-white;
    }

    .checkbox{
        @apply w-8 h-8 rounded border hover:border-green-dark;
    }

    .checkbox-checked{
        @apply border-green-dark;
    }

    .checkbox-default{
        @apply border-gray;
    }

    .checkbox>div{
        @apply bg-green-dark w-8 h-8 rounded;
    }

    .badge {
        @apply bg-green-light text-green-dark rounded
    }
}

.audi-theme {
    .btn-primary{
        @apply bg-aaplusPrimary text-white;
    }

    .checkbox{
        @apply w-8 h-8 rounded border hover:border-aaplusPrimary;
    }

    .checkbox-checked{
        @apply border-aaplusPrimary;
    }

    .checkbox-default{
        @apply border-gray;
    }

    .checkbox>div{
        @apply bg-aaplusPrimary w-8 h-8 rounded;
    }

    .badge {
        @apply bg-aaplusPrimary text-white rounded
    }
}

.btn-pill{
    @apply rounded-full;
}

/* MyWay Mesh / Gradient */
.myway-mesh{
    width: 100%;
    background-color:hsla(234,0%,100%,1);
    background-image:
    radial-gradient(at 0% 0%, #21B686, transparent 50%),
    radial-gradient(at 62% 0%, #049BD8, transparent 50%),
    radial-gradient(at 26% 0%, #00B2AB, transparent 50%),
    radial-gradient(at 100% 0%, #2F367D, transparent 50%),
    radial-gradient(at 48% 57%, #049BD8, transparent 50%),
    radial-gradient(at 63% 12%, #209CD8, transparent 50%),
    radial-gradient(at 100% 81%, #30367D, transparent 50%),
    radial-gradient(at 60% 100%, #049BD8, transparent 50%),
    radial-gradient(at 54% 36%, #049BD8, transparent 50%),
    radial-gradient(at 45% 81%, #049BD8, transparent 50%),
    radial-gradient(at 92% 0%, #2F367D, transparent 50%),
    radial-gradient(at 0% 19%, #21B686, transparent 50%),
    radial-gradient(at 29% 19%, #00B2AB, transparent 50%),
    radial-gradient(at 100% 20%, #2F367D, transparent 50%),
    radial-gradient(at 95% 14%, #394F9F, transparent 50%),
    radial-gradient(at 91% 39%, #394F9F, transparent 50%),
    radial-gradient(at 23% 38%, #00B2AB, transparent 50%),
    radial-gradient(at 82% 58%, #394F9F, transparent 50%),
    radial-gradient(at 0% 39%, #21B686, transparent 50%),
    radial-gradient(at 0% 88%, #00B18E, transparent 50%),
    radial-gradient(at 16% 83%, #00B2AB, transparent 50%),
    radial-gradient(at 100% 39%, #2F367D, transparent 50%),
    radial-gradient(at 100% 60%, #2F367D, transparent 50%),
    radial-gradient(at 11% 55%, #00B2AB, transparent 50%),
    radial-gradient(at 0% 60%, #03B279, transparent 50%),
    radial-gradient(at 0% 100%, #00B18E, transparent 50%),
    radial-gradient(at 26% 100%, #00B2AB, transparent 50%),
    radial-gradient(at 100% 100%, #30367D, transparent 50%),
    radial-gradient(at 89% 100%, #394F9F, transparent 50%);
}

.myway-mesh-vertical{
    width: 100%;
    background-color:#fff;
    background-image:
    radial-gradient(at 100% 0%, #21B686, transparent 50%),
    radial-gradient(at 100% 62%, #049BD8, transparent 50%),
    radial-gradient(at 100% 26%, #00B2AB, transparent 50%),
    radial-gradient(at 100% 100%, #2F367D, transparent 50%),
    radial-gradient(at 43% 48%, #049BD8, transparent 50%),
    radial-gradient(at 88% 63%, #209CD8, transparent 50%),
    radial-gradient(at 19% 100%, #30367D, transparent 50%),
    radial-gradient(at 100% 60%, #049BD8, transparent 50%),
    radial-gradient(at 64% 54%, #049BD8, transparent 50%),
    radial-gradient(at 19% 45%, #049BD8, transparent 50%),
    radial-gradient(at 100% 92%, #2F367D, transparent 50%),
    radial-gradient(at 81% 0%, #21B686, transparent 50%),
    radial-gradient(at 81% 29%, #00B2AB, transparent 50%),
    radial-gradient(at 80% 100%, #2F367D, transparent 50%),
    radial-gradient(at 86% 96%, #394F9F, transparent 50%),
    radial-gradient(at 61% 91%, #394F9F, transparent 50%),
    radial-gradient(at 61% 22%, #00B2AB, transparent 50%),
    radial-gradient(at 42% 83%, #394F9F, transparent 50%),
    radial-gradient(at 61% 0%, #21B686, transparent 50%),
    radial-gradient(at 12% 0%, #00B18E, transparent 50%),
    radial-gradient(at 17% 16%, #00B2AB, transparent 50%),
    radial-gradient(at 60% 100%, #2F367D, transparent 50%),
    radial-gradient(at 40% 100%, #2F367D, transparent 50%),
    radial-gradient(at 45% 13%, #00B2AB, transparent 50%),
    radial-gradient(at 40% 0%, #03B279, transparent 50%),
    radial-gradient(at 0% 0%, #00B18E, transparent 50%),
    radial-gradient(at 100% 26%, #00B2AB, transparent 50%),
    radial-gradient(at 0% 100%, #30367D, transparent 50%),
    radial-gradient(at 0% 89%, #394F9F, transparent 50%);
}
/* END MyWay Mesh / Gradient */

/* Video React styles */
.video-react .video-react-button,
.video-react .video-react-control-bar{
    display: none !important;
}

/* Loader styling (promo video loading) */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #21B686;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }